// src/pages/Testimonials.js

import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h1>Testimonials</h1>
      <p>Details about testimonials...</p>
    </div>
  );
};

export default Testimonials;
